import { docReady } from './utils';

import popoverInit from './popover';
import navbarTopDropShadow from './navbar-top';
import navbarDarkenOnScroll from './navbar-darken-on-scroll';
import draggableInit from './draggable';
import scrollbarInit from './scrollbar';
import scrollInit from './scroll';
import tooltipInit from './tooltip';

docReady(popoverInit);
docReady(navbarTopDropShadow);
docReady(navbarDarkenOnScroll);
docReady(draggableInit);
docReady(scrollbarInit);
docReady(scrollInit);
docReady(tooltipInit);